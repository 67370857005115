import { __awaiter, __generator, __values, __read, __spreadArray } from 'tslib';

if (!Array.prototype.any) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.any = function any() {
        return this.length > 0;
    };
}

if (!Array.prototype.compact) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.compact = function compact() {
        return this.reduce(function (memo, element) {
            if (element !== null && typeof element !== 'undefined') {
                memo.push(element);
            }
            return memo;
        }, []);
    };
}

if (!Array.prototype.compactMap) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.compactMap = function compactMap(callbackFn) {
        return this.reduce(function (memo, element, index, collection) {
            var maybe = callbackFn === null || callbackFn === void 0 ? void 0 : callbackFn(element, index, collection);
            if (maybe !== null && typeof maybe !== 'undefined') {
                memo.push(maybe);
            }
            return memo;
        }, []);
    };
}

if (!Array.prototype.mapAsync) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.mapAsync = function mapAsync(callback) {
        var _this = this;
        return Promise.all(this.map(function (element, index) { return callback(element, index, _this); }));
    };
}

if (!Array.prototype.compactMapAsync) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.compactMapAsync = function compactMapAsync(callbackFn) {
        return __awaiter(this, void 0, void 0, function () {
            var elements;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mapAsync(callbackFn)];
                    case 1:
                        elements = _a.sent();
                        return [2 /*return*/, elements.compact()];
                }
            });
        });
    };
}

if (!Array.prototype.cume) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.cume = function cume(callback) {
        var _this = this;
        var counter = 0;
        if (callback) {
            return this.map(function (element, index) {
                counter += callback(element, index, _this);
                return counter;
            });
        }
        return this.map(function (e) {
            if (typeof e === 'number') {
                counter += e;
            }
            return counter;
        });
    };
}

function isComparer(fn) {
    return typeof fn === 'function' && fn.length === 2;
}
var defaultComparer = function () {
    return function (a, b) {
        return a === b;
    };
};

if (!Array.prototype.distinct) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.distinct = function distinct(arg1) {
        var comparerFn = arg1 !== null && arg1 !== void 0 ? arg1 : defaultComparer();
        return this.reduce(function (memo, value) {
            if (!memo.some(function (v) { return comparerFn(v, value); })) {
                memo.push(value);
            }
            return memo;
        }, []);
    };
}

if (!Array.prototype.except) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.except = function except(other, arg2) {
        var comparerFn = arg2 !== null && arg2 !== void 0 ? arg2 : defaultComparer();
        return this.reduce(function (memo, element) {
            if (!other.some(function (v) { return comparerFn(v, element); }) &&
                !memo.some(function (v) { return comparerFn(v, element); })) {
                memo.push(element);
            }
            return memo;
        }, []);
    };
}

if (!Array.prototype.first) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.first = function first(callbackFn) {
        var e_1, _a;
        if (!callbackFn) {
            if (this.length > 0) {
                return this[0];
            }
        }
        if (callbackFn) {
            try {
                for (var _b = __values(this.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), index = _d[0], el = _d[1];
                    if (callbackFn(el, index, this)) {
                        return el;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return undefined;
    };
}

if (!Array.prototype.forEachAsync) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.forEachAsync = function forEachAsync(callbackFn) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(this.map(callbackFn))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
}

if (!Array.prototype.groupBy) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.groupBy = function groupBy(keyExtractFn, arg2) {
        var keyComparerFn = arg2 !== null && arg2 !== void 0 ? arg2 : defaultComparer();
        var r = [];
        this.forEach(function (value, index, array) {
            var key = keyExtractFn(value, index, array);
            var existing = r.find(function (p) { return keyComparerFn(key, p.key); });
            if (!existing) {
                existing = { key: key, values: [] };
                r.push(existing);
            }
            existing.values.push(value);
        });
        return r;
    };
}

if (!Array.prototype.innerJoin) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.innerJoin = function innerJoin(other, on) {
        var pairs = [];
        this.forEach(function (left) {
            other.forEach(function (right) {
                if (on(left, right)) {
                    pairs.push({ left: left, right: right });
                }
            });
        });
        return pairs;
    };
}

if (!Array.prototype.interleave) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.interleave = function interleave(callbackFn) {
        var r = [];
        for (var index = 0; index < this.length; index += 1) {
            r.push(this[index]);
            if (index !== this.length - 1) {
                r.push(callbackFn(this[index], this[index + 1]));
            }
        }
        return r;
    };
}

if (!Array.prototype.last) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.last = function last(callbackFn) {
        var _this = this;
        if (!callbackFn) {
            if (this.length > 0) {
                return this[this.length - 1];
            }
            return undefined;
        }
        return this.reduce(function (memo, element, index) {
            if (callbackFn(element, index, _this)) {
                return element;
            }
            return memo;
        }, undefined);
    };
}

if (!Array.prototype.leftJoin) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.leftJoin = function leftJoin(other, on) {
        var unmatchedLeft = new Set(this);
        var pairs = [];
        this.forEach(function (left) {
            other.forEach(function (right) {
                if (on(left, right)) {
                    pairs.push({ left: left, right: right });
                    unmatchedLeft.delete(left);
                }
            });
        });
        var leftOnly = Array.from(unmatchedLeft).map(function (left) { return ({
            left: left,
            right: null
        }); });
        return __spreadArray(__spreadArray([], __read(leftOnly)), __read(pairs));
    };
}

if (!Array.prototype.max) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.max = function max(callback) {
        var _this = this;
        var maxValue = null;
        this.forEach(function (element, index) {
            var _a;
            var t = (_a = callback === null || callback === void 0 ? void 0 : callback(element, index, _this)) !== null && _a !== void 0 ? _a : element;
            if (maxValue === null || maxValue < t) {
                maxValue = t;
            }
        });
        return maxValue;
    };
}

if (!Array.prototype.min) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.min = function min(callback) {
        var _this = this;
        var minValue = null;
        this.forEach(function (element, index) {
            var _a;
            var t = (_a = callback === null || callback === void 0 ? void 0 : callback(element, index, _this)) !== null && _a !== void 0 ? _a : element;
            if (minValue === null || minValue > t) {
                minValue = t;
            }
        });
        return minValue;
    };
}

if (!Array.prototype.none) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.none = function none() {
        return this.length === 0;
    };
}

if (!Array.prototype.outerJoin) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.outerJoin = function outerJoin(other, on) {
        var unmatchedLeft = new Set(this);
        var unmatchedRight = new Set(other);
        var pairs = [];
        this.forEach(function (left) {
            other.forEach(function (right) {
                if (on(left, right)) {
                    pairs.push({ left: left, right: right });
                    unmatchedLeft.delete(left);
                    unmatchedRight.delete(right);
                }
            });
        });
        var leftOnly = Array.from(unmatchedLeft).map(function (left) { return ({
            left: left,
            right: null
        }); });
        var rightOnly = Array.from(unmatchedRight).map(function (right) { return ({
            left: null,
            right: right
        }); });
        return __spreadArray(__spreadArray(__spreadArray([], __read(leftOnly)), __read(pairs)), __read(rightOnly));
    };
}

if (!Array.prototype.sum) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.sum = function sum(callback) {
        var _this = this;
        var counter = 0;
        if (callback) {
            this.forEach(function (element, index) {
                counter += callback(element, index, _this);
            });
        }
        else {
            this.forEach(function (e) {
                if (typeof e === 'number') {
                    counter += e;
                }
            });
        }
        return counter;
    };
}

if (!Array.prototype.toRecord) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.toRecord = function toRecord(keyExtractFn, valueExtractFn) {
        var entries = this.map(function (element, index, array) {
            return [
                keyExtractFn(element, index, array),
                valueExtractFn ? valueExtractFn(element, index, array) : element
            ];
        });
        return Object.fromEntries(entries);
    };
}

if (!Array.prototype.union) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.union = function union(other, arg2) {
        var comparerFn = arg2 !== null && arg2 !== void 0 ? arg2 : defaultComparer();
        return this.reduce(function (memo, element) {
            if (other.some(function (v) { return comparerFn(v, element); }) &&
                !memo.some(function (v) { return comparerFn(v, element); })) {
                memo.push(element);
            }
            return memo;
        }, []);
    };
}

var recordToArray = function (record) {
    return Object.entries(record).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return [key, value];
    });
};

/* eslint-disable import/prefer-default-export */
var range = function (from, to) {
    var size = Math.abs(to - from) + 1;
    var direction = to >= from ? 1 : -1;
    return __spreadArray([], __read(Array(size).keys())).map(function (v) { return from + v * direction; });
};

export { defaultComparer, isComparer, range, recordToArray };
