import qs from 'qs';
import { fetch } from 'cross-fetch';

class ShowmeFitAppGatewayClient {
    constructor(baseUrl, logger) {
        this.baseUrl = baseUrl;
        this.logger = logger;
    }
    async getApiHealth(args, options) {
        const { authorization } = args;
        const method = 'GET';
        const url = `${this.baseUrl}/api/health`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async getApiSessions(args, options) {
        const { authorization, userId } = args;
        const query = qs.stringify({ ['userId']: userId });
        const method = 'GET';
        const url = `${this.baseUrl}/api/sessions?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async postApiSessions(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/sessions`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async deleteApiSessionsBySessionId(args, options) {
        const { authorization, sessionId } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/sessions/${sessionId}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 204:
                return {
                    status: $status,
                    body: undefined
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 404:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiWeightTrackingCommandsAddWeighInByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/weight-tracking/commands/add-weigh-in/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiWeightTrackingCommandsDeleteWeighInByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/weight-tracking/commands/delete-weigh-in/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async getApiWeightTrackingViewsWeightHistoryByUserByUserId(args, options) {
        const { authorization, limit, fromKey, userId } = args;
        const query = qs.stringify({ ['limit']: limit, ['fromKey']: fromKey });
        const method = 'GET';
        const url = `${this.baseUrl}/api/weight-tracking/views/weight-history-by-user/${userId}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async getApiWeightTrackingViewsWeightSnapshotByUserByUserId(args, options) {
        const { authorization, userId } = args;
        const method = 'GET';
        const url = `${this.baseUrl}/api/weight-tracking/views/weight-snapshot-by-user/${userId}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiIdentityCommandsSendMagicLinkByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/identity/commands/send-magic-link/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiIdentityCommandsAssignRoleByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/identity/commands/assign-role/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiPlannerCommandsAssignRecipeByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/planner/commands/assign-recipe/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiPlannerCommandsUnassignRecipeByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/planner/commands/unassign-recipe/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiPlannerCommandsAddActivityByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/planner/commands/add-activity/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiPlannerCommandsDeleteActivityByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/planner/commands/delete-activity/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiPlannerCommandsAmendActivityByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/planner/commands/amend-activity/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async getApiPlannerViewsPlanDay(args, options) {
        const { authorization, userId, fromDate, toDate } = args;
        const query = qs.stringify({
            ['userId']: userId,
            ['fromDate']: fromDate,
            ['toDate']: toDate
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/planner/views/plan-day?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async getApiSubscriptionViewsUserSubscriptionStateByUserId(args, options) {
        const { authorization, userId } = args;
        const method = 'GET';
        const url = `${this.baseUrl}/api/subscription/views/user-subscription-state/${userId}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 404:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async putApiAppStoreCommandsAssignAppUserTokenByCommandId(args, options) {
        const { authorization, commandId, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/app-store/commands/assign-app-user-token/${commandId}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 201:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: undefined
                };
            case 403:
                return {
                    status: $status,
                    body: undefined
                };
            case 409:
                return {
                    status: $status,
                    body: undefined
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/chefs'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/chefs?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/chefs'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/chefs`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/chefs/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/chefs/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/chefs/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/chefs/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/chefs/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/chefs/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/collaborators'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/collaborators?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/collaborators'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/collaborators`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/collaborators/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/collaborators/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/collaborators/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/collaborators/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/collaborators/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/collaborators/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/cuisines'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/cuisines?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/cuisines'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/cuisines`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/cuisines/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/cuisines/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/cuisines/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/cuisines/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/cuisines/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/cuisines/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/dietary-requirements'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/dietary-requirements?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/dietary-requirements'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/dietary-requirements`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/dietary-requirements/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/dietary-requirements/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/dietary-requirements/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/dietary-requirements/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/dietary-requirements/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/dietary-requirements/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/equipments'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/equipments?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/equipments'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/equipments`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/equipments/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/equipments/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/equipments/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/equipments/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/equipments/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/equipments/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/products'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/products?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/products'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/products`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/products/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/products/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/products/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/products/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/products/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/products/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/product-groups'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/product-groups?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/product-groups'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/product-groups`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/product-groups/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/product-groups/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/product-groups/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/product-groups/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/product-groups/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/product-groups/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/recipes'(args, options) {
        const { authorization, sort, paginationWithCount, paginationPage, paginationPageSize, paginationStart, paginationLimit, fields, populate, filters, locale } = args;
        const query = qs.stringify({
            ['sort']: sort,
            ['pagination[withCount]']: paginationWithCount,
            ['pagination[page]']: paginationPage,
            ['pagination[pageSize]']: paginationPageSize,
            ['pagination[start]']: paginationStart,
            ['pagination[limit]']: paginationLimit,
            ['fields']: fields,
            ['populate']: populate,
            ['filters']: filters,
            ['locale']: locale
        });
        const method = 'GET';
        const url = `${this.baseUrl}/api/recipes?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'post/recipes'(args, options) {
        const { authorization, body } = args;
        const method = 'POST';
        const url = `${this.baseUrl}/api/recipes`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'get/recipes/{id}'(args, options) {
        const { authorization, populate, id } = args;
        const query = qs.stringify({ ['populate']: populate });
        const method = 'GET';
        const url = `${this.baseUrl}/api/recipes/${id}?${query}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'put/recipes/{id}'(args, options) {
        const { authorization, id, body } = args;
        const method = 'PUT';
        const url = `${this.baseUrl}/api/recipes/${id}`;
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {}),
                'Content-Type': 'application/json'
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
    async 'delete/recipes/{id}'(args, options) {
        const { authorization, id } = args;
        const method = 'DELETE';
        const url = `${this.baseUrl}/api/recipes/${id}`;
        const response = await fetch(url, {
            method,
            headers: {
                ...(typeof authorization !== 'undefined'
                    ? { ['authorization']: authorization }
                    : {})
            },
            ...options
        });
        const { status: $status } = response;
        this.logger?.('REST API Call', { method, url, status: $status });
        switch ($status) {
            case 200:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 400:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 401:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 403:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 404:
                return {
                    status: $status,
                    body: (await response.json())
                };
            case 500:
                return {
                    status: $status,
                    body: (await response.json())
                };
            default:
                throw new Error(`Unexpected status ${$status}`);
        }
    }
}

export { ShowmeFitAppGatewayClient };
