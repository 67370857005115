var fail = function (reason) { return ({
    success: false,
    reason: reason
}); };

var isFail = function (result) { return !result.success; };

var isSuccess = function (result) { return result.success; };

var success = function (data) {
    if (data === undefined) {
        return { success: true };
    }
    return {
        success: true,
        data: data
    };
};

export { fail, isFail, isSuccess, success };
